
mat-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 8px;
  border-width: 2px !important;
  border-color: grey !important;
  border-radius: 4px !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

mat-card mat-card-header {
  padding: 16px;
  display: flex;
  flex-direction: row;
}

mat-card mat-card-header:first-child mat-card-avatar {
  margin-right: 12px;
}

[dir=rtl] mat-card mat-card-header:first-child mat-card-avatar {
  margin-right: auto;
  margin-left: 12px;
}

mat-card mat-card-header:last-child mat-card-avatar {
  margin-left: 12px;
}

[dir=rtl] mat-card mat-card-header:last-child mat-card-avatar {
  margin-left: auto;
  margin-right: 12px;
}

mat-card mat-card-header mat-card-avatar {
  width: 40px;
  height: 40px;
}

mat-card mat-card-header mat-card-avatar .mat-user-avatar,
mat-card mat-card-header mat-card-avatar mat-icon {
  border-radius: 50%;
}

mat-card mat-card-header mat-card-avatar mat-icon {
  padding: 8px;
}

mat-card mat-card-header mat-card-avatar mat-icon > svg {
  height: inherit;
  width: inherit;
}

mat-card mat-card-header mat-card-avatar + mat-card-header-text {
  max-height: 40px;
}

mat-card mat-card-header mat-card-avatar + mat-card-header-text .mat-headline-6 {
  font-size: 14px;
}

mat-card mat-card-header mat-card-header-text {
  display: flex;
  flex: 1;
  flex-direction: column;
}

mat-card mat-card-header mat-card-header-text .mat-subhead {
  font-size: 14px;
}

mat-card > img,
mat-card > mat-card-header img,
mat-card mat-card-title-media img {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
}

mat-card mat-card-title {
  padding: 24px 16px 16px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
}

mat-card mat-card-title + mat-card-content {
  //padding-top: 0;
}

mat-card mat-card-title mat-card-title-text {
  flex: 1;
  flex-direction: column;
  display: flex;
}

mat-card mat-card-title mat-card-title-text .mat-subhead {
  padding-top: 0;
  font-size: 14px;
}

mat-card mat-card-title mat-card-title-text:only-child .mat-subhead {
  padding-top: 12px;
}

mat-card mat-card-title mat-card-title-media {
  margin-top: -8px;
}

mat-card mat-card-title mat-card-title-media .mat-media-sm {
  height: 80px;
  width: 80px;
}

mat-card mat-card-title mat-card-title-media .mat-media-md {
  height: 112px;
  width: 112px;
}

mat-card mat-card-title mat-card-title-media .mat-media-lg {
  height: 152px;
  width: 152px;
}

mat-card mat-card-content {
  display: block;
  padding: 16px;
}

mat-card mat-card-content > p:first-child {
  margin-top: 0;
}

mat-card mat-card-content > p:last-child {
  margin-bottom: 0;
}

mat-card mat-card-content .mat-media-xl {
  height: 240px;
  width: 240px;
}

mat-card .mat-actions, mat-card mat-card-actions {
  margin: 8px;
}

mat-card .mat-actions.layout-column .mat-mdc-button:not(.mat-mdc-icon-button), mat-card mat-card-actions.layout-column .mat-button:not(.mat-icon-button) {
  margin: 2px 0;
}

mat-card .mat-actions.layout-column .mat-mdc-button:not(.mat-mdc-icon-button):first-of-type, mat-card mat-card-actions.layout-column .mat-button:not(.mat-icon-button):first-of-type {
  margin-top: 0;
}

mat-card .mat-actions.layout-column .mat-mdc-button:not(.mat-mdc-icon-button):last-of-type, mat-card mat-card-actions.layout-column .mat-button:not(.mat-icon-button):last-of-type {
  margin-bottom: 0;
}

mat-card .mat-actions.layout-column .mat-mdc-button.mat-mdc-icon-button, mat-card mat-card-actions.layout-column .mat-button.mat-icon-button {
  margin-top: 6px;
  margin-bottom: 6px;
}

mat-card .mat-actions mat-card-icon-actions, mat-card mat-card-actions mat-card-icon-actions {
  flex: 1;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}

mat-card .mat-actions:not(.layout-column) .mat-mdc-button:not(.mat-mdc-icon-button), mat-card mat-card-actions:not(.layout-column) .mat-button:not(.mat-icon-button) {
  margin: 0 4px;
}

mat-card .mat-actions:not(.layout-column) .mat-mdc-button:not(.mat-mdc-icon-button):first-of-type, mat-card mat-card-actions:not(.layout-column) .mat-button:not(.mat-icon-button):first-of-type {
  margin-left: 0;
}

[dir=rtl] mat-card .mat-actions:not(.layout-column) .mat-mdc-button:not(.mat-mdc-icon-button):first-of-type, [dir=rtl] mat-card mat-card-actions:not(.layout-column) .mat-button:not(.mat-icon-button):first-of-type {
  margin-left: auto;
  margin-right: 0;
}

mat-card .mat-actions:not(.layout-column) .mat-mdc-button:not(.mat-mdc-icon-button):last-of-type, mat-card mat-card-actions:not(.layout-column) .mat-button:not(.mat-icon-button):last-of-type {
  margin-right: 0;
}

[dir=rtl] mat-card .mat-actions:not(.layout-column) .mat-mdc-button:not(.mat-mdc-icon-button):last-of-type, [dir=rtl] mat-card mat-card-actions:not(.layout-column) .mat-button:not(.mat-icon-button):last-of-type {
  margin-right: auto;
  margin-left: 0;
}

mat-card .mat-actions:not(.layout-column) .mat-mdc-button.mat-mdc-icon-button, mat-card mat-card-actions:not(.layout-column) .mat-button.mat-icon-button {
  margin-left: 6px;
  margin-right: 6px;
}

mat-card .mat-actions:not(.layout-column) .mat-mdc-button.mat-mdc-icon-button:first-of-type, mat-card mat-card-actions:not(.layout-column) .mat-button.mat-icon-button:first-of-type {
  margin-left: 12px;
}

[dir=rtl] mat-card .mat-actions:not(.layout-column) .mat-mdc-button.mat-mdc-icon-button:first-of-type, [dir=rtl] mat-card mat-card-actions:not(.layout-column) .mat-button.mat-icon-button:first-of-type {
  margin-left: auto;
  margin-right: 12px;
}

mat-card .mat-actions:not(.layout-column) .mat-mdc-button.mat-mdc-icon-button:last-of-type, mat-card mat-card-actions:not(.layout-column) .mat-button.mat-icon-button:last-of-type {
  margin-right: 12px;
}

[dir=rtl] mat-card .mat-actions:not(.layout-column) .mat-mdc-button.mat-mdc-icon-button:last-of-type, [dir=rtl] mat-card mat-card-actions:not(.layout-column) .mat-button.mat-icon-button:last-of-type {
  margin-right: auto;
  margin-left: 12px;
}

mat-card .mat-actions:not(.layout-column) .mat-mdc-button + mat-card-icon-actions, mat-card mat-card-actions:not(.layout-column) .mat-button + mat-card-icon-actions {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
}

mat-card mat-card-footer {
  margin-top: auto;
  padding: 16px;
}

@media screen and (-ms-high-contrast: active) {
  mat-card {
    border: 1px solid #fff;
  }
}

.flex {
  flex: 1;
  box-sizing: border-box;
}

.flex-grow {
  flex: 1 1 100%;
  box-sizing: border-box;
}

.flex-initial {
  flex: 0 1 auto;
  box-sizing: border-box;
}

.flex-auto {
  flex: 1 1 auto;
  box-sizing: border-box;
}

.flex-none {
  flex: 0 0 auto;
  box-sizing: border-box;
}

.flex-noshrink {
  flex: 1 0 auto;
  box-sizing: border-box;
}

.flex-nogrow {
  flex: 0 1 auto;
  box-sizing: border-box;
}

.flex-0 {
  flex: 1 1 100%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
}

mat-padding {
    padding: 15px;
}

// package card //

app-package-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

app-package-card mat-card-header {
  padding: 16px;
  display: flex;
  flex-direction: row;
}

app-package-card mat-card-header mat-card-header-text {
  display: flex;
  flex: 1;
  flex-direction: column;
}

app-package-card mat-card-title {
  padding: 24px 16px 16px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  position: relative;
}

app-package-card mat-card-title + mat-card-content {
  padding-top: 0;
}

app-package-card mat-card-title mat-card-title-text {
  flex: 1;
  flex-direction: column;
  display: flex;
}

app-package-card mat-card-content {
  display: block;
  padding: 16px;
}

app-package-card .mat-actions mat-card-icon-actions, mat-card mat-card-actions mat-card-icon-actions {
  flex: 1;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}

app-package-card app-package-card-footer {
  margin-top: auto;
  padding: 16px;
}

@media screen and (-ms-high-contrast: active) {
  app-package-card {
    border: 1px solid #fff;
  }
}
