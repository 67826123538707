.tabs-group {
  background: white;
  border: none !important;
  padding: 15px !important;
}

rw-tabs {
  i {
    font-family: FontAwesome;
    font-style: normal;
  }
}

rw-tabs {
  .tabs-group-header-items {
    flex: unset !important;
  }

  rw-tab-item {
    a {
      width: unset !important;
      height: 48px !important;
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}
