.popover-body {
  padding: 0px;
  font-family: Arial, Helvetica, sans-serif;
  -moz-box-shadow: 0px 1px 4px #AEAEAE, 0px -1px 4px #AEAEAE;
  -webkit-box-shadow: 0px 1px 4px #AEAEAE, 0px -1px 4px #AEAEAE;
  box-shadow: 0px 1px 4px #AEAEAE, 0px -1px 4px #AEAEAE;
}

.popover-content {
  max-width: 500px;
  min-width: 350px;
}

.popover-content-sm {
  width: 250px;
}

// tab overrides
li.nav-item {
  font-size: 14px;
}

ul.nav-tabs {
    background: white;
    margin-bottom:1px;
    &.nav {
      border: none;

      > li > button {
        height:48px;
        color: unset;
        border-radius: 0;
        border: none;
        padding-left: 24px;
        padding-right: 24px;

        &.active {
          border-bottom: 3px solid blue;
        }
      }
  }
}
