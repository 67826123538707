// svg color filters
.filter-blue {
  filter: invert(33%) sepia(49%) saturate(618%) hue-rotate(182deg) brightness(100%) contrast(88%);
}
.filter-royal{
  filter: invert(28%) sepia(96%) saturate(1467%) hue-rotate(219deg) brightness(91%) contrast(100%);
}
.filter-default {
  filter: invert(47%) sepia(8%) saturate(775%) hue-rotate(153deg) brightness(96%) contrast(93%);
}

.filter-green {
  filter: invert(46%) sepia(83%) saturate(383%) hue-rotate(85deg) brightness(96%) contrast(90%) !important;
}

.filter-red {
  filter: invert(12%) sepia(88%) saturate(4660%) hue-rotate(354deg) brightness(90%) contrast(105%) !important;
}

.filter-white {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(190deg) brightness(106%) contrast(101%) !important;
}

.filter-grey {
  filter: invert(24%) sepia(8%) saturate(775%) hue-rotate(153deg) brightness(96%) contrast(93%);
}
