// Imported scss to customize the css classes with the prefix provided
// to avoid the conflict with bootstrap classes
$prefix: 'p-' !default; // override primeflex variable
@import "../node_modules/primeflex/primeflex.scss";


@import "../node_modules/primeng/resources/primeng.css";
@import "../node_modules/primeicons/primeicons.css";
@import './styles/global/variables';
@import './styles/global/buttons';
@import './styles/global/dialogs';
@import './styles/global/cards';
@import './styles/global/primeng';
@import './styles/global/angmat';
@import './styles/global/ngbootstrap';
@import './styles/global/_uicommon.scss';

/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
  overflow-x: hidden;
  background-color: #F0F0F0;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 14px;
}

// this property is removed from rw-brand.min.css after upgrading the @rn-platform/frontend-shared-ui-common to 0.0.88
// after removing causing multiple ui issues so added here
* {
  letter-spacing: -0.02em; 
}

h1, h2, h3, .h1, .h2, .h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 200;
  color: #000 !important;
  line-height: 1.1;
}

h2 {
  font-size: 34px;
  margin-block-end: 0;
  margin-block-start: 0;
}

h3 {
  font-size: 28px;
}

h4, h5, h6 {
  font-weight: 300;
}

h5 {
  font-size: 16px;
}

.side-margins-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.error-text {
  color: red;
}

@import "../../ClientApp/node_modules/primeng/resources/primeng.min.css";
 // this is needed for the structural css
.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.padding-lr-md {
  padding-left: 8px;
  padding-right: 8px;
}

body .ui-paginator .ui-dropdown {
  margin-top: 5px;
}

body .ui-paginator .ui-dropdown-label {
  padding-top: 2px;
  padding-bottom: 2px;
}

body .ui-dialog .ui-dialog-content {
  padding: 0;
  padding: 0;
}

body .ui-widget {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-decoration: none;
}

.resource-loader {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 300px;

  .dark-image {
    margin: 0 auto;
    width: 375px;
  }

  h3 {
    margin: 12px 0;
  }
}

.no-href-link {
  cursor: pointer;
  text-decoration: none;
}

.no-href-link:disabled {
  cursor: not-allowed;
}

.side-nav-bar {
  width: $sidebar-width;

  div > a {
    cursor: pointer;
  }

  ul {
    margin-left: 8px;
    list-style: none;
    padding-left: 0px;
    margin-top: 0px;
  }

  ul > li {
    background-color: #f7f9fa;
    border-bottom: 1px solid #eaeaea;
    position: relative;
    display: block;
  }

  ul > li:first-child {
    border-top: 1px solid #eaeaea;
  }

  ul > li a {
    color: #6a6c6f;
    font-weight: 700;
    padding: 8px 20px;
    position: relative;
    display: block;
  }
}

.navbar-header {
  background-color: #F2F2F2;
  padding: 8px;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;

  > i {
    vertical-align: middle;
    margin-right: 10px;
  }

  > label {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px
  }
}
p-table > .ui-table {
  margin-left: -5px;
  margin-top: 10px;
}
.org-bar {
  width: auto;
}

.tab-content {
  background-color: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 15px;
  padding-left: 0px;
  border-top: none;
  &.no-padding {
    padding: 0px;
  }
}

.flex-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 20px;
}

.scroll-content {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  height: 90%
}

body .ui-message.ui-message-info {
  background-color: #FFDEDE;
  border: 0 none;
  color: #C00909;
  font-size: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
}

body .ui-message.ui-message-info .ui-message-icon {
  color: #C00909;
}

.content {
  width: 100%;
}

.fullTabPanel {
  width: 800px !important;
}

.break {
  word-break: break-word;
  overflow-wrap: break-word;
}

.overlay-container {
  position: relative;

  &.min-height-500px {
    min-height: 500px;
    padding: 0px 15px;
  }

  &.height-100percent {
    height: 100%;
  }
}

.overlay {
  background-color: #FFFFFF;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  opacity: 0.85;
  display: inline-flex;

  &.opacity-1 {
    opacity: 1;
  }
}

.overlay-content {
  align-self: center;
  margin: auto;
  color: #4564A2;
  font-size: 24px;
}

.detailsLink {
  cursor: pointer;
  color: #337ab7;
  text-decoration: none;
  font-weight: 700
}

.no-text-decoration{
text-decoration: none;
}

.full-container-padding-side-15 {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.help-block {
  font-size: 12px;
  font-style: unset;
  font-family:Arial, Helvetica, sans-serif;
  padding-top: 2px;
  padding-bottom: 2px
}

.text-right {
  text-align: right
}

.panelHeaderIcon {
  margin-right: 10px;
}


button.close {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none
}

.xToClose {
  color: #333;
  font-size: 18px;
  text-align: center;
  line-height: normal;
  margin-right: 3px
}

.navPanelHeader {
  background-color: #F2F2F2;
  padding: 8px;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}

.reversedRow {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.reversedColumn, .reversedRow {
  display: -ms-flexbox;
  display: flex;
}

.reversedColumn {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse
}

.reversedColumn, .reversedRow {
  display: -ms-flexbox;
  display: flex
}

.reversedRow {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse
}


.orderCancelLink {
  font-family: Arial, Helvetica, sans-serif;
  white-space: normal;
  padding-left: 14px;
  font-size: 14px;
  color: #1872c4;
  text-decoration: none;
  cursor: pointer
}

.orderCancelLink:hover {
  text-decoration: underline
}

.month-label{
  font-size: x-small;
  display: inline-flex;
}

.font-italic{
  font-style: italic
}

.height-42 {
  height: 42px !important;
}

.height-99-with-overflow{
  height: 99%;
  overflow: hidden auto;
}

.height-99{
  height: 99%;
  overflow:hidden;
}

.width-n-height {
  height: 42px !important;
  width: 60% !important;
}

.width-60perc {
  width: 60% !important;
}

.top-div-border {
  border-top: 1px solid #ddd;
}

.bot-div-border {
  border-bottom: 1px solid #ddd;
}

input::placeholder {
  color: grey;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
}

.orderCancelLink:disabled, .orderCancelLink[disabled] {
  opacity: .5;
  cursor: not-allowed;
  text-decoration: none;
  pointer-events: none;
}

button.orderCancelLink {
  background: 0 0;
  border: none;
}

.cancelLinkMargin {
  margin-right: 9px
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  background: #f7f9fa;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  color: #455A64;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

pre {
  display: block;
  padding: 11.5px;
  margin: 0 0 12px;
  font-size: 15px;
  line-height: 1.5;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 0;
}

.X-btn {
  color: #ddd !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  white-space: nowrap;
  font-size: 8px !important;
  line-height: 1.5;
  border-radius: 0;
}

.p-b-12 {
  padding-bottom: 12px;
}

hr {
  margin-top: 24px;
  margin-bottom: 24px;
  border: 0;
  border-top: 1px solid rgb(88 81 81);
  box-sizing: content-box;
  height: 0
}

.medium:not(.userDetailAction) {
  margin-bottom: 20px
}

.text_14 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.pullUpperRight {
  margin-top: -22px !important;
  margin-right: -22px;
}

.no-margins {
  margin: 0 !important;
}

.tree-view {
  padding: 18px 0px 34px 0px;
  margin-left: 0px;
  margin-right: 0px;
  height: 65vh;
}

.hide-y-overflow {
  overflow-y: hidden !important;
}

.display-flex {
  display: flex;
}

.marg-left-5px {
  margin-left: 5px;
}

.width-30-perc {
  width: 30%;
}

.width-96-perc {
  width: 96%;
}

.marg-8-left {
  margin-left: 8px;
}

.footer-top-border {
  border-top: 1px solid #D4D8DA;
  margin-left: 20px;
  margin-right: 20px;
}

.header-border-none {
  border-bottom: none;
}

.wlp-text {
  background-color: #E0E0E0 !important;
  padding: 10px;
  font-size: 11.8px;
}

.pad-20 {
  padding : 20px;
}

//!!! This Needs to be here because it affects salesforce-chat-wrapper.js not the ts file!!!//
.embeddedServiceHelpButton .helpButton .uiButton {
  position: absolute;
  background-color: #2A3690 !important;
  font-family: Arial, Helvetica, sans-serif;
  bottom: 38px !important;
  height: 83% !important;
  right: 6%;
}

.pad-bot-45{
  padding-bottom: 45px;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #2A3690;
}
.dockableContainer .sidebarHeader h2 {
  color: #FFFFFF !important;
  background-color: #000000 !important;
}

.dockableContainer .sidebarHeader {
  color: #FFFFFF !important;
  background-color: #000000 !important;
  box-sizing: border-box;
  border-radius: 6px 6px 0px 0px;
}
p-dropdown > div > .ui-dropdown-label-container{
  margin-left: 25px !important;
}
.addUserClass > div > p-dropdown > div > .ui-dropdown-label-container{
  margin-left: 8px !important;
}
.dockableContainer .backgroundImg, .embeddedServiceSidebarForm .backgroundImgColorMask {
  background-color: transparent !important;
  height: 0 !important;
}

.embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer:hover {
  height: 4.6%;
  bottom: 38px !important;
}
.embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer {
  height: 4.6%;
  bottom: 38px !important;
}
.embeddedServiceSidebarButton {
  background: #2FA351;
  text-underline-position: unset !important;
}
.sidebarHeader[embeddedService-chatHeader_chatHeader]{
  background-size: 9% 60%;
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 8px;
}
.dockableContainer .sidebarHeader h2 {
  color: #FFFFFF !important;
  background-color: #000000 !important;
  margin-left: 24px;
}

.bgColWhite{
  background-color: white;
}
.embeddedServiceLiveAgentStateChatAvatar .avatar {
  background-image: url(https://www.clipartmax.com/png/full/79-799881_international-checkout-call-center-icon.png) !important;
  color: transparent !important;
  background-color:transparent !important;
}

.embeddedServiceSidebarMinimizedDefaultUI .minimizedImage {
  display: none !important;
}
//!!!====================================================================================!!!//

.ui-toast-message.ui-shadow.ng-tns-c11-2.ng-trigger.ng-trigger-messageState.ui-toast-message-error ,
.p-toast-message.ui-shadow.ng-tns-c11-2.ng-trigger.ng-trigger-messageState.p-toast-message-error {
  background-color: #FF5340 !important;
  box-shadow: 0px 0px 0px #E27474;
  border-radius: 4px;
}
.userDemo {
  margin-top: -120px;
  font-family: Arial, Helvetica, sans-serif;
}
.noLeftPadding{
  padding-left: 0px !important;
}
#btnUninstall > div > button{
  margin-left: 0px !important;
}

.profileStatus > span > img {
  color: #198754;
  filter: invert(48%) sepia(79%) hue-rotate(86deg) brightness(100%);
}
#btnUninstall > div > button{
  color: rgba(0, 0, 0, 0.80);
}
.profileStatus > span{
  border: 1px solid #198754;
  border-radius: 99px;
  font-size: 14px;
  padding: 10px;
  color: #198754;
}
.pi-times{
  color: black !important;
}
.ui-sidebar-right{
  border-radius: 16px 0px 0px 16px;
}
app-search-control{
  width: 100%;
}
#btnEditUserProfile > div > button {
  color: rgba(0, 0, 0, 0.80);
}

@media only screen and (max-width: 798px) {
  /* For mobile phones: */
  .userDemo {
    margin-top: 0;
  }
}
