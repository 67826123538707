$brand-action: #2a6ca8; // Blue
$brand-main: #288a4f; // Right Networks Green
$brand-green: #06a646; // Right Networks Green part II: the revenge
$brand-dark-green: #0b833b;
$danger-red: #c30017;
$rootworks-gray: #2a3690;
$royal-blue: #3f57e8;
$white: #ffffff;
$sidebar-width: 210px;
$sidebar-offset: $sidebar-width - 15;
$neutral-gray: #d1d1d1;
