@import 'variables';
.login-notification {
  background: $white !important;
  color: black;

  .modal-header {
    padding: 20px 20px 0 20px;

    h3 {
      color: black !important;
      font-family: Effra, Roboto, Rubik;
      font-weight: 600;
      font-style: normal;
      font-size: 19px;
    }
  }

  .modal-body {
    border: none;
    font-family: Effra, Roboto, Rubik;
    font-size: 14px;
    color: #000;
    line-height: 19px;
  }

  .rn-btn {
    margin-bottom: 0px !important;
  }
}
.removeUsers {
  .modal-body{
    font-size: 14px !important;
  }
}
.modal-body-14{
  .modal-body{
    font-size: 14px !important;
  }
}
.confirmation {
  background: #FFFFFF !important;
  color: black;

  .modal-header {
    padding: 17px 24px 0 24px;

    h3 {
      color: black !important;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 24px;
    }
  }

  .modal-body {
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 19px;
    padding: 10px 24px 0 24px;

    .no-margin-well {
      min-height: 20px;
      padding: 19px;
      background: #F5F7FA;
      border: 1px solid #e8e8e8;
      color: #272727;
      border-radius: 0;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.05);

      ul {
        padding-left: 1em;
        margin-bottom: 0;

        li {
          padding: 6px;
        }

        li:last-child {
          padding-bottom: 0px;
        }
      }
    }

    p {
      margin-bottom: 0;
    }

    .p-field {
      margin-top: 0.5rem;

      label {
        color: #272727;
      }
    }
  }

  .modal-footer {
    padding: 17px 20px 17px 20px;

    app-button {
      margin-left: 7px;
    }
  }
}

.rn-modal-title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}

.modal-content {
  padding: 25px 30px;
  border: none !important;
  background-color: white;
  label, app-label span {
    font-size: 14px;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;

  &.no-border {
    border: none;
  }
}

.modal-header .header-black {
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  color: #000000 !important;
}

.modal-header h5 {
  margin: 16px 0;
}

.modal-header h3 {
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  color: #ffffff !important;

  &.modal-title {
    line-height: 1.5;
    margin: 0;
    font-weight: 200 !important;
    color: black !important;
    font-size: 28px !important;
  }

  &.info-modal-title{
    color: #000 !important;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 130%;
    letter-spacing: -0.4px;
  }
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  border-top: none;
  padding: 20px;
  background-color: white;
  .flex-row {
    place-content: center flex-end;
    align-items: center;
  }

  .rn-btn + .rn-btn {
    margin-left: 20px;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}


.adduser-modal-window {
  height: 100%
}

.adduser-modal-window .modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 32px);
}

.adduser-modal-window .title {
  font-size: 20px;
  margin-bottom: 5px;
}

.adduser-modal-window .modal-header {
  padding-bottom: 0px;
  height: 78px;
}

.adduser-modal-window .modal-header h3 {
  font-size: 20px;
  font-weight: 600;
}

.adduser-modal-window .modal-body {
  height: 100%;
  overflow-y: auto;
}

.adduser-modal-window .modal-footer {
  height: 98px;
  align-items: normal;
 // justify-content: space-between;
}

.addUserModalButtons {
  margin: 25px;
  display: inline-block;
}

.adduser-modal-window {
  font-family: Arial, Helvetica, sans-serif;
  color: #000000;
  font-style: normal;
  max-height: calc(100% - 60px);
  overflow: hidden !important;
  cursor: default;

  :focus {
    outline: none;
  }
}

.adduser-modal-window .border-bottom-darker {
  border-bottom: 1px solid #aaaaaa;
}

.adduser-modal-window, .user-edit-addons {
  .panel-heading {
    padding: 0px 15px 0px 0px;
  }
}

.adduser-modal-window .user-summary {
  label {
    margin-top: 5px;
  }

  app-label {
      margin-bottom: 8px;
  }

  label .addons:not(:first-child) {
    margin-top: -5px;
  }
}

.adduser-modal-window .user-details {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: #000;
  margin-top: 0px !important;
}

.adduser-modal-window, .user-edit-addons {
  h2 {
    font-size: 20px;
    font-weight: 600;
  }
}

.adduser-modal-window, .user-edit-addons {
  h3 {
    font-size: 16px;
    text-transform: none;
    margin-bottom: 2px;
  }
}

.adduser-modal-window, .user-edit-addons {
  h4 {
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.4px;
    margin-bottom: 6px;
  }
}

.adduser-modal-window h5 {
  font-weight: 600;
  font-size: 15px;
}

.user-edit-addons {
  font-family: Effra, Roboto, Rubik;
}

.adduser-modal-window .totalDue td {
  padding: 6px 10px 6px 10px !important;

  p {
    margin-bottom: 0px;
  }
}

.username-role {
  font-family: Effra, Roboto, Rubik;
  font-weight: 400;
}

.adduser {
  .ui-dialog-content {
    overflow: visible !important;

    .modal-content {
      padding: 25px 30px 0 30px !important;
    }
  }
}

.adduser-modal-window .no-padding {
  padding: 0;
}

.adduser-modal-window .text-right {
  text-align: right;
}

.adduser-modal-window .text-center {
  text-align: center;
}

.adduser-modal-window .text-top {
  vertical-align: top;
}

.adduser-modal-window .weight-400 {
  font-weight: 400 !important;
}

.adduser-modal-window .weight-600 {
  font-weight: 600;
}

.adduser-modal-window .no-padding-left {
  padding-left: 0;
}

.adduser-modal-window .no-padding-right {
  padding-right: 0;
}

.adduser-modal-window #cancelButton {
  font-weight: 400;
  color: rgba(24, 114, 196, 0.996078431372549);
}

.adduser-modal-window .orderTotals {
  background-color: #dff0d8 !important;
}

.adduser-modal-window .futureBill {
  background-color: #FFFFFF !important;
  font-family: Arial, Helvetica, sans-serif;
}

.adduser-modal-window .currentPayment {
  background-color: #f7f7f7 !important;
}


.adduser-modal-window .step-desc {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 22px;
}

.adduser-modal-window .discounted {
  color: grey;
  text-decoration: line-through;
}

.adduser-modal-window .packageBackground {
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(174, 174, 174, 0.996078431372549);
}

.adduser-modal-window .black {
  color: black !important;
}

.adduser-modal-window .cart-review {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(215, 215, 215);
  padding: 10px;
}

.adduser-modal-window, .user-edit-addons {
  .category-desc {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}


.select-package-addon-panel-title {
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  margin-bottom: 2px;
}

.category-desc {
  font-family: Effra, Roboto, Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.rename-desc {
  padding: 8px 8px 0px 8px;
}

.user-list {
  overflow-y: auto;
  width: 100%;
  margin: 8px;
  max-height: 300px;
}

h3.modal-header-text-black {
  color: black !important;
}

.ui-dropdown-panel .ui-dropdown-item,
.p-dropdown-panel .p-dropdown-item {
  color: rgb(0 0 0);
  background-color: white
  
}
.ui-dropdown-panel .ui-dropdown-items-wrapper {
    overflow: auto;
    background-color:white;
};

.p-dropdown-panel {
  box-shadow: 0 1px 3px #0000004d;

  .p-dropdown-items-wrapper {
    overflow: auto;
    background-color: white;

    .p-dropdown-items {
      padding: 0.4em;
      border: 0 none;
      p-dropdownitem {
        .p-dropdown-item {
         text-wrap: unset;
          &:hover {
            background: #3f57e81c;
          }
       }
      }
      .p-disabled {
        span {
          color: #c3c0c0 !important;
        }
      }
    }
  }
}

.assign-package-dropdown .p-dropdown-items-wrapper {
  max-width: initial;
}

.confirmation > .modal-footer > .modal-footer-seperator {
  width: 0% !important;
}
.modalBody{
  font-size: 14px !important;
}

.cancel-subs{
  padding: 24px !important;
  border: 1px solid rgba(100, 100, 100, 0.3) !important;
  border-radius: 10px !important;
}

.cancel-subs > .modal-body{
  font-size: 14px !important;
 }

.body-text{
  font-size: 14px !important;
}
.packageAssigmentDialog  > .ui-dialog-content {
  max-height: calc(100vh - 32px);
  overflow-y: scroll !important;
}
